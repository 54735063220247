.custom-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 500px;
  max-width: 80%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.modal-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.btn-close {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.modal-body {
  padding: 20px;
}

.mt-2 {
  margin-top: 10px;
}

.d-flex {
  display: flex;
}

.justify-content-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.quarter {
  width: 25%;
  padding: 10px;
}

.inner-quarter {

  padding: 10px;
  border-radius: 5px;
}

.quarter-1 {
  margin-right: 10px;
}

.quarter-2 {
  margin-right: 10px;
}

.quarter-4 {
  margin-right: 10px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  gap: 3px;
}

.btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.btn-secondary {
  background-color: #ccc;
  color: #fff;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.taxPayable-modal-body {
  padding: 20px;
}

.taxPayable-modal-body .inner-quarter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taxPayable-modal-body .inner-quarter span {
  font-weight: bold;
}

.taxPayable-modal-body .inner-quarter input {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}