.status-container {
  width: 100%;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: relative;
  margin-top: 30px;
}

.status-line {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* margin-bottom: 50px; */
  padding-bottom: 20px;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 60px; /* Initial margin */
  white-space: nowrap;
  /* text-wrap: wrap; */
  position: relative;
  margin-bottom: 50px;
}

.status-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.status-circle.active {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
  border-radius: 50% !important;
}

.tick-mark {
  font-size: 24px;
}

.status-label {
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: calc(100% + 5px); /* Position label below circle, adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: smaller;
  font-weight: bold;
}

.status-connector {
  height: 4px;
  position: absolute;
  top: 31% !important;
  transform: translateY(-50%);
  background-color: #ccc;
  left: 20px; /* Adjusted to start from the center of the first circle */
  right: 20px; /* Adjusted to end at the center of the last circle */
  z-index: 0; /* Ensure it's below circles */
}

/* Media queries for adjusting margin */
@media (max-width: 1400px) {
  .status-item {
    margin: 10px 60px;
  }
}

@media (max-width: 1200px) {
  .status-item {
    margin: 10px 50px;
  }
}

@media (max-width: 992px) {
  .status-item {
    /* margin: 10px 10px; */
    text-wrap: wrap;
  }
  .status-circle {
    margin-top: 10px; /* Adjusted margin for smaller screens */
  }
  .status-label {
    top: calc(100% + 10px); /* Adjusted label position for smaller screens */
  }
  .status-line {
    padding-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .status-item {
    /* margin: 10px 20px; */
    text-wrap: wrap;
  }
  .status-circle {
    margin-top: 10px; /* Adjusted margin for smaller screens */
  }
  .status-label {
    top: calc(100% + 10px); /* Adjusted label position for smaller screens */
  }
}

@media (max-width: 576px) {
  .status-item {
    margin: 10px 20px;
    text-wrap: wrap;
  }
  .status-circle {
    margin-top: 10px; /* Adjusted margin for smaller screens */
  }
  .status-label {
    top: calc(100% + 10px); /* Adjusted label position for smaller screens */
  }
}

@media (max-width: 460px) {
  .status-item {
    margin: 10px 10px;
    text-wrap: wrap;
  }
  .status-circle {
    margin-top: 10px; /* Adjusted margin for smallest screens */
  }
  .status-label {
    top: calc(100% + 10px); /* Adjusted label position for smaller screens */
  }
}

@media (max-width: 360px) {
  .status-item {
    margin: 10px 3px;
    text-wrap: wrap;
  }
  .status-circle {
    margin-top: 10px; /* Adjusted margin for smallest screens */
  }
  .status-label {
    top: calc(100% + 10px); /* Adjusted label position for smaller screens */
  }
}
@media (min-width: 993px) and (max-width: 1395px) {
  .status-connector{
    top: 70%;
    transform: translateY(-70%);
  }
  .status-circle {
    margin-top: -10px; /* Adjusted margin for smallest screens */
  }
}