/* course.css */
.learning-container {
  max-width: 1200px;
  margin: 1rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 4rem;
  transition: box-shadow 0.3s ease;
}

.learning-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
  color: #333;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-item {
  width: 300px;
  margin: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 1rem;
  border-radius: 1rem;
}

.video-item:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.thumbnail-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thumbnail-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.video-item h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #333;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.video-item h3:hover {
  color: #337ab7;
}