body {
  background-color: #ffffff !important;
}


:root {
  --theme-green: #65ac4d;
  --theme-blue: #073c5c;
  --theme-orange: #c0392b;
  --theme-dark-gray: #454545;
  --theme-light-gray: #b3b3b3;
  --theme-highlight: #f1c40f;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--theme-blue);
}

body::-webkit-scrollbar {
  width: 0.6em;
  /* display: none; */
}

body::-webkit-scrollbar-thumb {
  background: var(--theme-green);
  border-radius: 25px;
}

.bg-orange {
  background-color: var(--theme-orange) !important;
}
.text-orange {
  color: var(--theme-orange) !important;
}
.bg-green {
  background-color: var(--theme-green) !important;
}
.text-green {
  color: var(--theme-green) !important;
}
.bg-blue {
  background-color: var(--theme-blue) !important;
}
.text-blue {
  color: var(--theme-blue) !important;
}
.bg-dark-gray {
  background-color: var(--theme-dark-gray) !important;
}
.text-dark-gray {
  color: var(--theme-dark-gray) !important;
}
.bg-light-gray {
  background-color: var(--theme-light-gray) !important;
}
.text-light-gray {
  color: var(--theme-light-gray) !important;
}
.bg-highlight {
  background-color: var(--theme-highlight) !important;
}
.text-highlight {
  color: var(--theme-highlight) !important;
}

/* // landing page css */
.all-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.app-logo {
  width: 50%;
}
.app-logo-sm {
  width: 50%;
}
a {
  text-decoration: none;
}
.btn-login a {
  color: var(--theme-blue) !important;
}
.btn-signup a {
  color: var(--theme-green) !important;
}
.btn-signup {
  background-color: var(--theme-blue);
  color: var(--theme-green) !important ;
  border: none;
}
.btn-login {
  background-color: var(--theme-green);
  color: var(--theme-blue) !important;
  border: none;
}

/* sign in page */
.signin-page {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signin-page form {
  max-width: 600px;
}
/* sign up page */
.signup-page {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signup-page form {
  max-width: 600px;
}

.popup{
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 10px;
}
/* // questions page css */
.quest-alert {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.question {
  background-color: var(--theme-green);
  padding: 1rem;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-next {
  background-color: var(--theme-blue);
  color: #ffffff;
  border: none;
}
.btn-next:hover {
  color: var(--theme-blue);
  background-color: white;
}
.quest-input {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ffffff;
}

/* Home Page CSS */
.main {
  max-width: 700px;
  margin: auto auto;
}

.scaffold {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.active {
  background-color: transparent !important;
  position: relative;
  color: #ffffff !important;
  border-radius: 0px !important;
  z-index: 100;
}
.active::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% + 2rem);
  background-color: var(--theme-green);
  top: -50%;
  left: 0px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  transition: popUp 0.5s infinite ease-in-out;
}

.edit-button-dashboard::before {
  content: "";
  background-image: url(../public/assets/images/icons/pencil-square.svg);
  border: none;
  height: 16px;
  width: 16px;
  display: inline-block;
  align-self: center;
}

.scaffoldBody {
  margin: 100px 0px 0px 0px;
}
.year-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.year-box img {
  display: none;
}

@media screen and (max-width: 700px) {
  .year-box {
    justify-content: space-between;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .year-box img {
    display: block;
  }
  .scaffold {
    position: fixed;
    top: initial;
    bottom: 0;
    box-shadow: rgba(100, 100, 100, 0.4) 0px -2px 10px;
  }
  .scaffoldBody {
    margin: 0px 0px 100px 0px;
  }
  .active::after {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
}

/* Profile Page CSS */

.accordion-button::after {
  background-image: url(../public/assets/images/icons/caret-down-fill.svg) !important;
}
.form-control input:focus-visible {
  outline: none;
}
.edit-button::before {
  content: "";
  background-image: url(../public/assets/images/icons/pencil-square.svg);
  border: none;
  height: 16px;
  width: 16px;
  display: inline-block;
  align-self: center;
  margin-right: 8px;
}
.contact-card {
  border-radius: 1rem;
}

.radio-questions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: max-content;
  margin: 1rem 0;
}

.radio-questions label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  border: 2px solid var(--theme-blue);
  margin: 0.3rem 0;
  border-radius: 100px;
  padding: 0.1rem 0.5rem 0.1rem 0;
}

.radio-questions input {
  height: 1.5rem;
  width: 1.5rem;
  margin: 1rem 1.5rem;
}
.suggestion {
  background-color: var(--theme-green);
  color: var(--theme-blue);
  border-radius: 100px;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.suggestion button {
  background-color: white;
  color: var(--theme-blue);
  cursor: pointer;
  border: none;
  padding: 2px 10px;
  margin: 0;
  font-size: large;
  font-weight: 700;
  outline: 10px solid white;
}

.suggestion button:hover {
  background-color: var(--theme-blue);
  color: white;
  outline: 10px solid var(--theme-blue);
}

.suggestion button:focus {
  outline: none;
}

.suggestion button:nth-child(1) {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-right: 2px solid var(--theme-blue);
}

.suggestion button:nth-child(2) {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-left: 2px solid var(--theme-blue);
}

.nav-item {
  flex-basis: auto !important;
}

.subnav {
  display: flex;
}
.year-box {
  display: flex;
  align-items: center;
}

.dropdown-logo {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #b6fff2;
  border-radius: 50%;
  margin-left: 8px;
}

.dropdown-toggle::after {
  display: none !important;
}
.dropdown-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-logo img {
  width: 60%;
}
.year-drop {
  border-radius: 20px !important;
  /* padding-left: 10px !important; */
  display: flex;
  align-items: center;
}

.scanner-icon {
  height: 50px;
  margin: 10px;
  cursor: pointer;
}

.whatsapp-btn{
  background: var(--theme-green);
}

.taxPayable-modal-body{
  height: 65vh;
  overflow: auto;
}

.taxPayable-modal-body::-webkit-scrollbar{
  width: 0.5rem;
}

.taxPayable-modal-body::-webkit-scrollbar-thumb{
  background: var(--theme-light-gray);
  border-radius: 25px;
}

.quarter{
  height: max-content;
  width: 90%;
  min-width: 325px;
  margin: 0.5rem;
  font-weight: 600;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  text-align: left;
  padding:10px 10%;
  display: flex;
  flex-direction: column;
}

.inner-quarter {
  display: flex;
  justify-content: space-between;
}

.bold-text {
  font-weight: 700;
}

.regime-dropdown {
  padding: 0.5rem 1.5rem; /* Add some padding */
  font-size: 16px; /* Increase font size */
  /* border: 1px solid #ccc; Add border */
  border-radius: 20px; /* Rounded corners */
  background: rgb(69, 69, 69);
  color: #ffffff;
  /* background-color: #f9f9f9;  Light background color */
  cursor: pointer; /* Change cursor to pointer */
}

.regime-dropdown option {
  color: #ffffff;
}

.regime-dropdown:focus {
  border-color: #fff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.quarterB {
  height: 250px;
  min-width: 325px;
  margin: 0.5rem;
  background: grey;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quarterB .inner-quarter {
  flex: 1 1 100%;
  font-weight: 500;
  color: black;
  font-size: 1.2rem;
}

.employer-card{
  height: 50px;
  width: 100%;
  /* background: grey; */
  border-radius: 15px;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: black;
}

.employer-container{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 10px;
}


.pan-tooltip{
  z-index: 9000;
  background-color: white !important;
  /* border-radius: 10px !important; */
  border: 1.5px solid black !important;
  opacity: 1 !important;
}

.react-tooltip-arrow {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.error-page h1 {
  color: #e74c3c; /* Red color for the heading */
}

.error-page h5 {
  margin-top: 10px;
  color: #333; /* Dark color for the text */
}

.error-page div {
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #3498db; /* Blue color for the contact information */
}
