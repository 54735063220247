.language-selector{
    background: var(--theme-green);
    border: 1px solid #a7a7a7;
    border-radius: 25px;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed !important;
    bottom: 1rem;
    right: 1rem;
    z-index: 9999;
}

@media screen and (max-width: 700px) {
    .language-selector{
        bottom: 4rem;
        right: 1rem;
    }
}
    